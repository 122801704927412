@tailwind base;
@tailwind components;
@tailwind utilities;

div, p {
  overflow:visible
}

div, ul {
  padding-bottom:5px;
}

p {
  padding-bottom:10px;
}

li {
  margin-left:0.5em;
  margin-bottom: 0.6em;
  line-height: 1.1em;
}

ul {
  padding-top:10px;
  padding-bottom:10px;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .2em; /* Adjust as needed */
  opacity:0
}

.typewriter.nodelay {
  opacity:1;
  animation: 
  typing 3.5s steps(30, end),
  blink-caret .5s step-end infinite;
}

.typewriter.delay-1 {
  animation: 
  typing 3.5s steps(30, end),
  blink-caret .5s step-end infinite;
}

.typewriter.delay-2 {
  animation:
  typing 3.5s steps(30, end),
  blink-caret .5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

.grow { 
  transition: all .2s ease-in-out; 
}
  
.grow:hover { 
  transform: scale(1.05); 
}

.grid {
  overflow:visible
}

.gridTextWrapper {
  font-size: 0.8em;
  line-height: 1.3;
  padding:1.0em;
  padding-top:1.2em;
  color: rgb(102 102 102);
}

.gridImgWrapper img {
  width:100%
}

h2,h3,h4,h5,h6 {
  text-align:center;
  margin-bottom:5px;
  font-weight:bold;
}

h4 {
  font-size:125%
}